export default () => ({
    cartId: null,
    cartData: null,
    complexProductDataItems: [],
    isCartDataValid: false,
    isCartDataLoading: true,
    shouldFetchPaymentAndShippingAfterLogin: false,
    padData: null,
    selectedPaymentType: '',
    isFetchingCartWithPostcode: false,
    isAddToCartPending: false,
    isAssociatedProductCardClosed: false,
    giftCards: [],
    storedGiftCards: [],
    additionalPaymentMethodIdForGiftCard: '',
    loader: { count: 0, text: '' },
});
