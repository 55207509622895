import { transformCartItem } from '@assets/cart';
import { formatPrice } from '@assets/price';
import { isModivoClubGoldCardSku } from '@modivo-club-assets/modivo-club';

export default {
    cartFetched: state => {
        return !!state.cartData;
    },

    checkoutCartDataFetched: (state, getters) => {
        if (getters.cartFetched) {
            const {
                extCart: { ext_shipping_addresses },
                available_payment_methods,
                selected_payment_method,
            } = state.cartData;

            return !!(
                ext_shipping_addresses?.length &&
                available_payment_methods &&
                !!selected_payment_method
            );
        }

        return false;
    },

    cartItems: (state, getters) => {
        if (
            getters.cartFetched &&
            state.cartData.extCart &&
            state.cartData.extCart.items
        ) {
            return state.cartData.extCart.items.map(cartItem => {
                const {
                    id,
                    sku: skuVariant,
                    product: {
                        sku,
                        name,
                        url_key,
                        image: { url },
                    },
                    quantity,
                    prices,
                } = cartItem;

                const {
                    discount_percent: cartDiscountPercent,
                    original_price: regularPriceTotal,
                    formatted_original_price: regularPriceTotalFormatted,
                    final_price: finalPriceTotal,
                    formatted_final_price: finalPriceFormattedTotal,
                    final_item_price: finalPrice,
                    item_original_price: regularPrice,
                    formatted_final_item_price: finalPriceFormatted,
                    formatted_item_original_price: regularPriceFormatted,
                    discount_percent_by_special_price: discountPercent,
                } = prices;

                return {
                    ...cartItem,
                    id,
                    sku,
                    skuVariant,
                    name,
                    quantity,
                    url_key,
                    image: {
                        src: url,
                    },
                    price: {
                        discount:
                            discountPercent === 0
                                ? null
                                : `${discountPercent}%`,
                        cartDiscount:
                            cartDiscountPercent === 0
                                ? null
                                : `${cartDiscountPercent}%`,
                        regular: {
                            amount: regularPrice,
                            formatted: regularPriceFormatted,
                        },
                        regularTotal: {
                            amount: regularPriceTotal,
                            formatted: regularPriceTotalFormatted,
                        },
                        promotional: {
                            amount: finalPrice,
                            formatted: finalPriceFormatted,
                        },
                        promotionalTotal: {
                            amount: finalPriceTotal,
                            formatted: finalPriceFormattedTotal,
                        },
                    },
                };
            });
        }

        return [];
    },

    cartItemsWithPaths: (_, getters) => {
        return getters.cartItems.map(item =>
            transformCartItem({
                item,
            })
        );
    },

    cartItemsCount: (_, getters) => {
        return getters.cartItems.reduce((count, item) => {
            return count + item.quantity;
        }, 0);
    },

    isModivoClubGoldCardInCart: (_, getters) => {
        return getters.cartItems.some(({ product }) =>
            isModivoClubGoldCardSku(product.sku)
        );
    },

    isOnlyModivoClubGoldCardInCart: (_, getters) => {
        return getters.cartItems.every(({ product }) =>
            isModivoClubGoldCardSku(product.sku)
        );
    },

    complexProductDataItemsSkusMap: state => {
        const { complexProductDataItems } = state;

        if (complexProductDataItems.length) {
            return complexProductDataItems.reduce((map, item) => {
                map[item.id] = item;

                return map;
            }, {});
        }

        return {};
    },

    isCartEmpty: (_, getters) => {
        return !getters.cartItemsCount;
    },

    /**
     * @todo: PWA-950 - refactor
     */
    inpostPickupPointCode: (state, getters) => {
        if (getters.cartFetched) {
            const pickupPoint = state.cartData.extCart.inpost_point;

            return pickupPoint === null ? '' : pickupPoint;
        }

        return '';
    },

    storePickupPointCode: (state, getters) => {
        if (getters.cartFetched) {
            const pickupPoint = state.cartData.extCart.store_pickup_place_id;

            return pickupPoint === null ? '' : pickupPoint;
        }

        return '';
    },

    shippingPointId: (state, getters) => {
        if (getters.cartFetched) {
            const pickupPoint =
                state.cartData.extCart?.shipping_point?.shipping_point_id;

            return pickupPoint === null ? '' : pickupPoint;
        }

        return '';
    },

    appliedShipping: (state, getters) => {
        if (getters.checkoutCartDataFetched) {
            const {
                extCart: { ext_shipping_addresses: shippingAddresses },
            } = state.cartData;

            const [appliedAddress] = shippingAddresses;

            return appliedAddress;
        }

        return null;
    },

    appliedCouponCode: (state, getters) => {
        if (getters.cartFetched) {
            const {
                cartData: { applied_coupons: appliedCoupons },
            } = state;

            if (appliedCoupons && appliedCoupons.length) {
                return appliedCoupons[0].code;
            }
        }

        return null;
    },

    subTotal: (state, getters) => {
        if (getters.cartFetched) {
            const {
                formatted_subtotal_including_tax: subTotal,
            } = state.cartData.extCart.prices;

            return subTotal;
        }

        return '';
    },

    subTotalNumber: (state, getters) => {
        if (getters.cartFetched) {
            const {
                subtotal_including_tax: subTotal,
            } = state.cartData.extCart.prices;

            return subTotal.value;
        }

        return null;
    },

    discount: (state, getters) => {
        if (getters.cartFetched) {
            const { discount } = state.cartData.extCart.prices;

            if (discount) {
                return discount.amount.extFormat;
            }
        }

        return '';
    },

    discountNumber: (state, getters) => {
        if (getters.cartFetched) {
            const { discount } = state.cartData.extCart.prices;

            if (discount) {
                const {
                    amount: { value },
                } = discount;

                return value;
            }
        }

        return null;
    },

    dutyAmount: (state, getters) => {
        if (getters.cartFetched) {
            const { duty } = state.cartData.extCart.prices;

            if (duty) {
                const { formatted_amount: dutyAmount } = duty;

                return dutyAmount;
            }
        }

        return null;
    },

    grandTotal: (state, getters) => {
        if (getters.cartFetched) {
            const {
                formatted_grand_total: grandTotal,
            } = state.cartData.extCart.prices;

            return grandTotal;
        }

        return '';
    },

    grandTotalNumber: (state, getters) => {
        if (getters.cartFetched) {
            const { grand_total: grandTotal } = state.cartData.extCart.prices;

            return grandTotal.value;
        }

        return null;
    },

    subTotalWithDiscount: (state, getters) => {
        if (getters.cartFetched) {
            const {
                formatted_subtotal_with_discount_excluding_tax: subtotalWithDiscount,
            } = state.cartData.extCart.prices;

            return subtotalWithDiscount;
        }

        return '';
    },

    formattedSpecialPriceTotalDiscount: (state, getters) => {
        if (!getters.cartFetched) {
            return '';
        }

        const {
            formatted_special_price_total_discount: formattedSpecialPriceTotalDiscount,
        } = state.cartData.extCart.prices.extended_cart_discount;

        if (formattedSpecialPriceTotalDiscount == null) {
            return '';
        }

        return `-${formattedSpecialPriceTotalDiscount}`;
    },

    unavailableProductsInCart: (_, getters) => {
        if (getters.cartItems) {
            const unavailable = getters.cartItems.filter(item => {
                const { ext_stock_count, quantity } = item;

                return quantity > ext_stock_count;
            });

            return unavailable;
        }

        return [];
    },

    cartProductsSkus: (_, getters) => {
        if (getters.cartItems) {
            return getters.cartItems.map(item => item.product.sku);
        }

        return [];
    },

    paymentMethods: state => state.padData?.paymentMethods || [],

    deliveryMethods: state => state.padData?.deliveryMethods || [],

    storesOptions: state => state.padData?.storesOptions || [],

    storesIds: (_, getters) =>
        getters.storesOptions.map(({ storeId }) => storeId),

    storeId: (_, getters) => getters.storesIds?.[0] || '',

    allPadOptions: (_, getters) =>
        getters.storesOptions.flatMap(({ storeId, options }) =>
            options.map(option => ({
                ...option,
                storeId,
                paymentType: getters.paymentMethods.find(
                    ({ id }) => id === option.paymentMethodId
                ).type,
            }))
        ),

    selectedDeliveryMethodCode: (_, getters) =>
        getters.appliedShipping?.selected_shipping_method?.carrier_code || '',

    selectedPaymentMethodCode: state =>
        state.cartData?.selected_payment_method?.code || '',

    selectedDeliveryMethod: (_, getters) => {
        return getters.deliveryMethods.find(
            ({ code }) => code === getters.selectedDeliveryMethodCode
        );
    },

    selectedPaymentMethod: (_, getters) => {
        return getters.paymentMethods.find(
            ({ code }) => code === getters.selectedPaymentMethodCode
        );
    },

    selectedPadOptions: (_, getters) => {
        const {
            selectedDeliveryMethod,
            selectedPaymentMethod,
            allPadOptions,
        } = getters;

        return allPadOptions
            .filter(
                option =>
                    !selectedDeliveryMethod ||
                    option.deliveryMethodId === selectedDeliveryMethod.id
            )
            .filter(
                option =>
                    !selectedPaymentMethod ||
                    option.paymentMethodId === selectedPaymentMethod.id
            );
    },

    deliveryCostNumber: (_, getters) => {
        const prices = [
            ...new Set(getters.selectedPadOptions.map(({ price }) => price)),
        ];

        return prices.length === 1 ? prices[0] : null;
    },

    deliveryCost: (_, getters, __, rootGetters) => {
        const { deliveryCostNumber } = getters;

        if (deliveryCostNumber !== null) {
            return formatPrice(
                deliveryCostNumber,
                rootGetters['config/locale'],
                rootGetters['config/currency']
            );
        }

        return null;
    },

    deliveryTitle: (_, getters) => getters.selectedDeliveryMethod?.title || '',

    isAnyGiftCardActive: state =>
        state.giftCards.some(({ enabled }) => enabled),

    areAnyGiftCardsStored: state => !!state.storedGiftCards?.length,

    cashbackCard: (state, _, __, rootGetters) =>
        state.giftCards.find(
            ({ cardNumber }) =>
                cardNumber === rootGetters['modivoClub/cashbackCardNumber']
        ),

    giftCards: (state, _, __, rootGetters) =>
        state.giftCards.filter(
            ({ cardNumber }) =>
                cardNumber !== rootGetters['modivoClub/cashbackCardNumber']
        ),

    isCashbackCardActive: (_, getters, __, rootGetters) => {
        return (
            rootGetters['modivoClub/isModivoClubGoldMember'] &&
            getters.cashbackCard?.enabled
        );
    },

    cashbackCardCovered: (_, getters) => {
        if (
            !getters.isCashbackCardActive ||
            getters.isOnlyModivoClubGoldCardInCart
        ) {
            return 0;
        }

        return getters.cashbackCard.covered.amount;
    },

    giftCardCovered: (_, getters) =>
        getters.giftCards
            .filter(({ enabled }) => enabled)
            .reduce((sum, { covered }) => sum + (covered?.amount || 0), 0),

    isGiftCardOrCashbackCoveringEntireOrder: (_, getters) =>
        getters.giftCardCovered + getters.cashbackCardCovered >=
        getters.grandTotalNumber,

    additionalPaymentMethodCodeForGiftCard(_, getters, rootState) {
        return (
            getters.paymentMethods.find(
                ({ id }) =>
                    id === rootState.cart.additionalPaymentMethodIdForGiftCard
            )?.code || ''
        );
    },
};
